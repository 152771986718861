import styled from 'styled-components'
import { breakpoints } from 'src/utils/styles/global-styles'

export const Header = styled.h1`
  font-family: 'P22MackinacProBook';
  font-size: 38px;
  margin-bottom: 17px;
  margin-top: 17px;
  line-height: 53px;
  letter-spacing: 0.19px;
  color: ${(props) => props.theme.grayDark};
  font-weight: 300;
  @media (max-width: ${breakpoints.l}px) {
    text-align: center;
    margin-top: 0px;
  }
  @media (max-width: ${breakpoints.s}px) {
    font-size: 28px;
    line-height: 32px;
    margin: 8px 0px 0px;
  }
`

export const Subheader = styled.h2`
  color: ${(props) =>
    props.blue ? props.theme.blueDark : props.red ? props.theme.coral : props.theme.grayDark};
  font-family: 'P22MackinacProBook';
  font-size: 26px;
  line-height: 32px;
  margin: 8px 0px;
  @media (max-width: ${breakpoints.s}px) {
    font-size: 26px;
    line-height: 32px;
  }
  @media (max-width: 360px) {
    font-size: 24px;
  }
`

export const ButtonContainer = styled.div`
  display: block;
  margin-right: 0px;
  margin-top: 0px;
  width: ${(props) => (props.fullWidth ? '100%' : '370px')};
  text-align: left;
  button {
    width: 100%;
  }
  @media (max-width: ${breakpoints.l}px) {
    margin: 0px auto;
    text-align: center;
  }
  @media (max-width: ${breakpoints.s}px) {
    width: 100%;
  }
`
export const Centered = styled.div`
  @media (max-width: ${breakpoints.l}px) {
    display: flex;
    justify-content: center;
    padding-top: 30px;
  }
  @media (max-width: ${breakpoints.s}px) {
    padding-top: 10px;
    .text-m {
      font-size: 16px !important;
    }
  }
`

export const FrameProductOptions = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  row-gap: 20px;
  margin-top: 16px;
  align-items: ${(props) => (props.basend ? 'basend' : 'flex-end')};
  @media (max-width: ${breakpoints.l}px) {
    justify-content: center;
  }
  @media (max-width: ${breakpoints.s}px) {
    flex-wrap: wrap;
    justify-content: flex-start;
    flex-direction: row;
  }
`
export const FrameSelection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  border: ${(props) => (props.blue ? '3px solid #2178af' : '1px solid #9199A0')};
  padding: ${(props) => (props.blue ? '13px' : '15px')};
  border-radius: 5px;
  cursor: pointer;
  margin: 0px 10px 0px 0px;
  -webkit-tap-highlight-color: transparent;
  position: relative;
  min-width: 165px;
  @media (max-width: ${breakpoints.s}px) {
    width: 47%;
    min-width: auto;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
  }
  @media (max-width: 375px) {
    width: 46%;
    border: ${(props) => (props.blue ? '3px solid #2178af' : '1px solid #9199A0')};
    padding: ${(props) => (props.blue ? '10px' : '12px')};
  }
`
export const FrameSizeContainer = styled.div``
export const Text = styled.p`
  font-family: 'FilsonPro' !important;
  font-size: 16px;
  line-height: 24px;
  margin: 3px 0px 0px 0px;
  padding: 0px;
  max-width: 140px;
`
export const PriceContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  display: relative;
`
export const FramePrice = styled.p`
  font-family: 'FilsonPro' !important;
  color: ${(props) => (props.red ? props.theme.coral : props.theme.grayDark)};
  margin: 0px;
  @media (max-width: ${breakpoints.s}px) {
    font-size: 14px;
    margin: 0px;
  }
  @media (max-width: 360px) {
    font-size: 13px;
  }
`
export const FramePriceStrike = styled(FramePrice)`
  text-decoration: line-through;
  padding-right: 7px;
`
export const Discount = styled.p`
  font-family: 'FilsonProBold' !important;
  color: ${(props) => props.theme.blueDark};
  position: absolute;
  right: 50px;
  bottom: -27px;
  background-color: ${(props) => props.theme.cream};
  padding: 0px 10px;
  @media (max-width: ${breakpoints.s}px) {
    font-size: 13px;
    margin: 0px;
    right: auto;
    left: 10px;
    bottom: -10px;
    padding: 0px 5px;
  }
`
export const FrameDiscountContainer = styled.div`
  display: flex;
  justify-content: space-between;
`

export const ColorSelection = styled.div`
  text-align: center;
  margin: ${(props) => (props.smallRadio ? '4px 0px 0px 3px' : '0px 12px 0px 0px')};
  cursor: pointer;
  -webkit-tap-highlight-color: transparent;
  display: flex;
  flex-direction: column;
  align-items: center;
  @media (max-width: 420px) {
    margin: ${(props) =>
      props.smallRadio
        ? '4px 0px 0px 3px'
        : props.mediumRadio
        ? '0px 6px 0px 0px'
        : '0px 12px 0px 0px'};
  }
`
export const CustomizationOption = styled.div`
  margin: 30px 0px;
  text-align: left;
  span {
    font-family: 'FilsonProBold' !important;
  }
`

export const BuyWithPrimeContainer = styled.div`
  width: 300px;
  background-color: ${(props) => props.theme.cream};
  margin: 0px;
  visibility: ${(props) => (props.visible ? 'visable' : 'hidden')};
  height: ${(props) => (props.visible ? 'auto' : '0px')};
  @media (max-width: ${breakpoints.l}px) {
    margin: auto;
  }
  @media (max-width: ${breakpoints.s}px) {
    box-shadow: ${(props) => (props.isFixed ? 'rgba(0, 0, 0, 0.1) 0px -4px 4px' : 'none')};
    padding: ${(props) => (props.isFixed ? '0px 5%' : '0px')};
    position: ${(props) => (props.isFixed ? 'fixed' : 'relative')};
    width: 100%;
    bottom: 0px;
    right: 0px;
    z-index: 1000;
  }
`

export const ColorSelectionContainer = styled.div`
  display: flex;
  flex-direction: column;
`

export const LimitedEdition = styled.p`
  color: ${(props) => props.theme.grayDark};
  font-family: 'P22MackinacProBook' !important;
  padding: 10px 0px 0px 0px;
  font-size: 20px;
  margin: 0px;
`
export const BogoContainer = styled.div`
  margin: 0px 0px 15px 0px;
`
export const RedSubheader = styled(LimitedEdition)`
  color: ${(props) => props.theme.coral};
  margin-bottom: 0px;
  padding-top: 0px;
  font-family: 'P22MackinacProBook' !important;
  line-height: normal;
`
export const Bogo = styled.p`
  color: ${(props) => props.theme.coral};
  margin: 0px 0px 10px;
  span {
    font-family: 'P22MackinacProBook' !important;
    color: ${(props) => props.theme.coral};
  }
`
export const BlackFridayBubble = styled.p`
  background-color: ${(props) =>
    props.blue ? props.theme.blueDark : props.pink ? props.theme.pink : props.theme.grayDark};
  font-family: 'FilsonPro' !important;
  color: ${(props) => (props.pink ? props.theme.grayDark : '#fff')};
  border-radius: 25px;
  padding: 2px 15px;
  font-size: 12px;
  margin: 0px;
  position: absolute;
  top: ${(props) => (props.isSelected ? '-13px' : '-11px')};
  right: ${(props) => (props.isSelected ? '8px' : '10px')};
  min-width: 100px;
  text-align: center;
`
export const BlackFridayMessage = styled.p`
  text-align: left;
  font-family: ${(props) => (props.dark ? 'FilsonPro' : 'FilsonProBook')} !important;
  @media (max-width: ${breakpoints.s}px) {
    text-align: ${(props) => (props.left ? 'left' : 'right')};
    margin-right: ${(props) => (props.right ? '12px' : '0px')};
    font-size: ${(props) => (props.small ? '14px' : '16px')};
    margin-bottom: ${(props) => (props.small ? '0px' : '16px')};
  }
  b {
    font-family: 'FilsonProBold';
    @media (max-width: 350px) {
      font-size: 14px;
    }
  }
  @media (max-width: 350px) {
    font-size: 14px;
  }
`
export const BlackFridayCalBubble = styled.p`
  display: block;
  background-color: ${(props) => props.theme.grayDark};
  font-family: 'FilsonPro' !important;
  color: white;
  border-radius: 25px;
  padding: 2px 10px;
  font-size: 13px;
  margin: 0px;
  position: absolute;
  top: 0px;
  right: 30px;
  @media (max-width: ${breakpoints.s}px) {
    right: 25px;
    font-size: 12px;
  }
  @media (max-width: 350px) {
    font-size: 11px;
  }
`
export const BlackFridayCalNoPlusBubble = styled.p`
  display: block;
  background-color: ${(props) => props.theme.grayDark};
  font-family: 'FilsonPro' !important;
  color: white;
  border-radius: 25px;
  padding: 2px 10px;
  font-size: 13px;
  margin: 0px;
  position: absolute;
  top: -20px;
  right: 7px;
  @media (max-width: ${breakpoints.l}px) {
    font-size: 12px;
    right: 0px;
  }
  @media (max-width: 350px) {
    font-size: 11px;
  }
`
export const CtaContainer = styled.div`
  display: flex;
  flex-direction: ${(props) => (props.showBwpCtaFirst ? 'column-reverse' : 'column')};
`
export const Highlights = styled.p`
  margin: 0px 4px;
`
export const HighlightContainer = styled.div`
  margin: 20px 0px 32px;
`
export const ListRow = styled.div`
  display: flex;
  align-items: center;
`
export const AmazonText = styled.p`
  text-align: center;
  width: 300px;
  margin: 8px 0px;
  font-size: 14px;
  @media (max-width: ${breakpoints.l}px) {
    margin: 6px auto;
  }
  @media (max-width: ${breakpoints.s}px) {
    width: 100%;
  }
`
