import styled, { css } from 'styled-components'
import { breakpoints } from 'src/utils/styles/global-styles'
import { darken } from 'polished'

export const StyleText = styled.p`
  font-family: 'FilsonPro' !important;
  font-size: ${(props) => (props.blue ? '13px' : '16px')};
  margin: ${(props) => (props.blue ? '0px' : '5px 3px 0px 0px')};
  color: ${(props) =>
    props.red
      ? props.theme.coral
      : props.blue
      ? props.theme.blueDark
      : props.oos
      ? '#9199A0'
      : props.theme.grayDark};
  text-decoration: ${(props) => (props.isDiscounted ? 'line-through' : 'none')};
`
export const SubLabel = styled.h3`
  font-size: 22px;
  line-height: 32px;
  margin: ${(props) => (props.oos ? '24px 0px 0px' : '24px 0px 12px')};
  color: ${(props) => (props.red ? props.theme.coral : props.theme.grayDark)};
`

export const CalMaxIcon = styled.div``
export const StyleOptionsContainer = styled.div`
  display: flex;
`
export const SelectionOption = styled.div`
  cursor: pointer;
  flex: 0 0 auto;
  white-space: normal;
  word-wrap: break-word;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  margin-right: 8px;
  width: 160px;
  padding: 9px;
  width: ${(props) => (props.center ? '80px' : props.selected ? `160px` : '158px')};
  border: ${(props) =>
    props.selected
      ? `2px solid ${props.theme.blueDark}`
      : props.oos
      ? '2px solid #9199A0'
      : '1px solid #E9E9E9'};
  border-radius: 5px;
  position: relative;
  background-color: ${(props) => (props.oos ? '#FAFAFA' : 'transparent')};
  opacity: ${(props) => (props.oos ? '90%' : 'transparent')};
  text-align: ${(props) => (props.center ? 'center' : 'left')};
  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: ${(props) => (props.oos ? 'block' : 'none')};
    background: linear-gradient(
      to top right,
      transparent 0%,
      transparent calc(50% - 1px),
      #9199a0 calc(50% - 1px),
      #9199a0 calc(50% + 1px),
      transparent calc(50% + 1px),
      transparent 100%
    );
  }
`
export const StyleDescription = styled.p`
  color: #4f4e4e;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 1.3;
  margin: 5px 0px 8px;
`
export const LimitedAvailable = styled.p`
  font-family: 'FilsonProRegularItalic' !important;
  margin-top: 0px;
  margin-bottom: 16px;
  color: ${(props) => (props.red ? props.theme.coral : props.theme.grayDark)};
  @media (max-width: ${breakpoints.s}px) {
    font-size: 14px;
  }
`
export const Badge = styled.p`
  border-radius: 18px;
  background: ${(props) => darken(0.1, props.theme.coral)};
  color: white;
  padding: 4px 10px 0px;
  font-size: 19px;
  line-height: none;
  font-family: 'FilsonPro' !important;
  margin-left: 24px;
  margin-top: 10px;
  min-width: ${(props) => (props.fast ? '150px' : 'auto')};
  text-align: center;
  @media (max-width: ${breakpoints.xl}px) {
    font-size: 15px;
  }
  @media (max-width: ${breakpoints.l}px) {
    font-size: 19px;
  }
  @media (max-width: ${breakpoints.s}px) {
    font-size: 15px;
    min-width: ${(props) => (props.fast ? '130px' : 'auto')};
    margin-top: 20px;
    margin-left: 10px;
  }
  @media (max-width: 375px) {
    font-size: 13px;
  }
`
export const HeaderContainer = styled.div`
  display: flex;
  align-items: center;
`
export const PickPriceContainer = styled.div`
  margin-top: ${(props) => (props.topMargin ? '24px' : '0px')};
`

export const GiftModeImage = styled.div`
  width: 175px;
  position: absolute;
  left: 20px;
  top: 20px;
  @media (max-width: 1500px) {
    width: 23%;
  }
  @media (max-width: ${breakpoints.s}px) {
    width: 120px;
    left: 10px;
    top: 10px;
  }
  @media (max-width: 400px) {
    width: 90px;
  }
`
export const CalFeatureRow = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 16px;
  @media (max-width: ${breakpoints.s}px) {
    flex-direction: column;
  }
`
export const CalFeature = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  text-align: center;
`
export const CalFeatureHeading = styled.h3`
  margin: 0px;
  font-size: 16px;
`
export const CalFeatureText = styled.p`
  margin: 0px !important;
  padding: 5px;
  font-size: 12px;
`
export const IconContainer = styled.div`
  margin: auto;
  margin-top: 0px;
  margin-bottom: 10px;
  height: 100px;
  width: 100px;
  @media (max-width: ${breakpoints.s}px) {
    margin-bottom: 10px;
  }
`

export const PlusPlanIncludes = styled.p`
  text-align: center;
  margin: 10px 0px !important;
  text-decoration: ${(props) => (props.underline ? 'underline' : 'none')};
`
export const ProductSelectionBoxWrapper = styled.div`
  position: relative;
`
const productSelectionBoxCss = css`
  padding: 10px 20px;
  border-radius: 8px;
  margin-bottom: 10px;
`

export const ProductSelectionBox = styled.section`
  ${productSelectionBoxCss}
  border: 3px solid ${(props) => props.theme.blueDark};
  background-color: ${(props) => props.theme.blueLightest};
  p {
    margin-left: 40px;
  }
  li {
    font-family: 'FilsonProBook';
    font-weight: normal !important;
    b {
      font-family: 'FilsonProBold';
    }
  }
  button {
    width: 100%;
    margin-bottom: 10px;
  }
  @media (max-width: ${breakpoints.s}px) {
    padding-bottom: 10px;
  }
`

export const ClickableProductSelectionBox = styled.section`
  ${productSelectionBoxCss}
  border: 1px solid ${(props) => props.theme.grayLight};
  background-color: white;
  cursor: pointer;
  :hover {
    border: 1px solid ${(props) => props.theme.blueDark};
  }
  @media (max-width: ${breakpoints.s}px) {
    padding-bottom: 30px;
  }
`
export const ProductSelectionHeaderRow = styled.section`
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  @media (max-width: ${breakpoints.s}px) {
    flex-direction: column;
    align-items: flex-start;
  }
`
export const ProductSelectionPricePointCss = css`
  font-size: 22px;
  font-family: 'FilsonProBook';
  font-weight: 300;
  line-height: 26px;
  letter-spacing: 0.1px;
  margin: 0;
  strike {
    font-size: 16px;
    color: ${(props) => props.theme.grayLight};
    padding-right: 3px;
  }
  @media (max-width: 1250px) {
    font-size: 20px;
    strike {
      font-size: 14px;
    }
  }
  @media (max-width: ${breakpoints.l}px) {
    font-size: 22px;
    strike {
      font-size: 16px;
    }
  }
`
export const ProductSelectionPricePoint = styled.section`
  ${ProductSelectionPricePointCss};
  color: ${(props) => props.theme.coral};
  display: flex;
`

export const ClickableProductSelectionPricePoint = styled.section`
  ${ProductSelectionPricePointCss}
  color: ${(props) => props.theme.coral};
  display: flex;
  align-items: center;
`
export const ProductSelectionHeader = styled.section`
  position: relative;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  @media (max-width: ${breakpoints.s}px) {
    align-items: flex-start;
  }
`
export const RadioIconContainer = styled.div`
  width: 24px;
  height: 24px;
  margin-right: 16px;
  align-self: center;
  @media (max-width: ${breakpoints.s}px) {
    margin-top: 24px;
  }
`
export const MostPopular = styled.span`
  background-color: ${(props) => props.theme.blueDark};
  color: white;
  border-radius: 28px;
  padding: 5px 10px;
  z-index: 10;
  align-self: center;
  margin-left: 24px;
`
export const PriceAndExplainerContainer = styled.section`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  padding-top: 15px;
  text-align: right;
  @media (max-width: 1730px) {
    padding-top: 0px;
  }
  @media (max-width: 600px) {
    padding-top: 0px;
  }
  @media (max-width: ${breakpoints.s}px) {
    align-items: flex-start;
    margin-left: 40px;
  }
`
export const OneTimePurchase = styled.section`
  margin: 0;
  font-size: 12px;
  line-height: 1;
  max-width: 170px;
  @media (max-width: 1730px) {
    text-align: right;
  }
  @media (max-width: ${breakpoints.s}px) {
    font-size: 12px;
    max-width: 100%;
    text-align: left;
  }
  @media (max-width: 460px) {
    max-width: 200px;
  }
`
const SaveInfoCss = css`
  font-family: 'P22MackinacProBold';
  font-size: 22px;
  margin-top: 24px;
  @media (max-width: ${breakpoints.s}px) {
    margin-bottom: 0px;
  }
  @media (max-width: 1250px) {
    font-size: 20px;
  }
  @media (max-width: ${breakpoints.l}px) {
    font-size: 22px;
  }
`
export const PlanHeading = styled.h3`
  ${SaveInfoCss};
`

export const SaveInfo = styled.h3`
  ${SaveInfoCss};
  @media (max-width: 1510px) {
    width: ${(props) => (props.calendar ? '300px' : '100%')};
  }
  @media (max-width: 1400px) {
    width: ${(props) => (props.calendar ? '250px' : '150px')};
  }
  @media (max-width: 1250px) {
    width: ${(props) => (props.calendar ? '210px' : '150px')};
  }
  @media (max-width: 1100px) {
    width: ${(props) => (props.calendar ? '175px' : '150px')};
  }
  @media (max-width: ${breakpoints.l}px) {
    width: 300px;
  }
  @media (max-width: ${breakpoints.s}px) {
    width: 100%;
  }
`
export const DropDownContainer = styled.div`
  border-radius: 0px !important;
  display: flex;
  align-items: stretch;
  margin-top: ${(props) => (props.margin ? '-30px' : '0px')};
  @media (max-width: ${breakpoints.s}px) {
    p {
      font-size: 15px;
    }
  }
  @media (max-width: 400px) {
    p {
      font-size: 14px;
    }
  }
`

export const DropDownContainerOptions = styled(DropDownContainer)`
  flex-direction: column;
  box-shadow: 0px 8px 16px 0px rgb(0 0 0 / 20%);
`

export const DropDownLabel = styled.div`
  padding: 0px;
  margin: 0px;
  padding: 5px 15px;
  border: 1px solid black;
  width: calc(100% - 40px);
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  p {
    margin: 0px;
  }
  @media (max-width: ${breakpoints.s}px) {
    p {
      font-size: 15px;
    }
  }
  @media (max-width: 400px) {
    padding: 5px 8px;
    p {
      font-size: 14px;
    }
  }
  @media (max-width: 350px) {
    p {
      font-size: 13px;
    }
  }
`
export const DropDownLabelOption = styled(DropDownLabel)`
  width: 100%;
  border-top: none;
  border-left: 1px solid black;
  border-right: 1px solid black;
  border-bottom: 1px solid black;
  cursor: pointer;
`
export const DropDownImageContainer = styled.div`
  width: 40px;
  border-top: 1px solid black;
  border-right: 1px solid black;
  border-bottom: 1px solid black;
  cursor: pointer;
`
export const DropDownIconContainer = styled.div`
  width: 16px;
  height: 10px;
  margin: auto;
  padding-top: 15px;
`
export const Disclosure = styled.p`
  font-family: 'FilsonProRegularItalic' !important;
  color: ${(props) => darken(0.1, props.theme.blueDark)};
  a {
    color: ${(props) => darken(0.1, props.theme.blueDark)};
  }
`
export const ColorContainer = styled.div`
  display: flex;
  align-items: center;
`
export const ComingSoonDisclosure = styled.p`
  font-family: 'FilsonProBold' !important;
  color: ${(props) => props.theme.blueDark};
`
export const ThumbnailVideo = styled.div`
  width: ${(props) => (props.isFrame ? '100px' : '60px')};
  @media (max-width: 1360px) {
    width: ${(props) => (props.isFrame ? '100px' : '40px')};
  }
  @media (max-width: 1160px) {
    width: ${(props) => (props.isFrame ? '100px' : '30px')};
  }
  @media (max-width: ${breakpoints.l}px) {
    width: ${(props) => (props.isFrame ? '100px' : '60px')};
  }
  @media (max-width: 750px) {
    width: ${(props) => (props.isFrame ? '100px' : '40px')};
  }
  @media (max-width: 550px) {
    width: ${(props) => (props.isFrame ? '70px' : '40px')};
  }
  @media (max-width: 400px) {
    width: ${(props) => (props.isFrame ? '60px' : '27px')};
  }
  @media (max-width: 350px) {
    width: ${(props) => (props.isFrame ? '60px' : '20px')};
  }
`
export const CalendarVideoThumbnail = styled.img`
  max-width: 100%;
`
export const VideoContainer = styled.div`
  position: relative;
  padding-bottom: 56.25%;
  overflow: hidden;
  max-width: 100%;
  height: auto;
  iframe,
  object,
  embed {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: none;
    box-shadow: none;
  }
  border-top-right-radius: 40px;
  border-bottom-right-radius: 40px;
  @media (max-width: ${breakpoints.l}px) {
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
  }
`
export const BogoContainer = styled.div`
  display: flex;
  flex-direction: ${(props) => (props.reverse ? 'row-reverse' : 'row')};
  gap: 16px;
  @media (max-width: 1850px) {
    flex-direction: ${(props) => (props.reverse ? 'column-reverse' : 'column')};
    gap: 0px;
  }
`
export const PriceContainer = styled.div`
  display: flex;
  gap: 4px;
`
